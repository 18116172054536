import Navbar from "./components/Navbar/Navbar";
import HomePage from "./pages/HomePage/HomePage";


function App() {
  return (
    <div>
      <Navbar></Navbar>
      <div>
        <HomePage></HomePage>
      </div>
    </div>
  );
}

export default App;
