import React from 'react';
import './Navbar.scss';
import logo from '../../assets/images/7eats logo.png'

const Navbar = () => {

    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos === 0) {
            document.getElementById("navbar").style.background = `transparent`
        } else {
            // setTimeout(() => {
            document.getElementById("navbar").style.background = '#ECDED1';
            // }, 600)
        }


        if (prevScrollpos > currentScrollPos) {
            document.getElementById("navbar").style.top = "0";
        } else {
            document.getElementById("navbar").style.top = "-80px";
        }
        prevScrollpos = currentScrollPos;
    }


    const handleClickScroll = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div className='n' id='navbar'>
            <div className='logo'>
                <img src={logo} onClick={() => handleClickScroll('hero')} alt='hero'></img>
            </div>
            <div className='menu-l'>
                <div onClick={() => handleClickScroll('products')}>Products</div>
                {/* <div onClick={() => handleClickScroll('about-products')}>About</div> */}
                <div onClick={() => handleClickScroll('contact')}>Contact</div>
                {/* {user ? <div>Cart</div> : <div>Log In</div>} */}
                <div>Order Now</div>
            </div>
            <div className='menu-s'>
                <div>Menu</div>
            </div>
        </div>
    )
}

export default Navbar