import React from 'react'
import './ProductCard.scss'

const ProductCard = ({ name, price, ing, image, description, background }) => {
    // console.log(background);
    return (
        <div className='p-products'>
            <div className='p-image'>
                <img src={image} className='pics' alt='juice1'></img>
            </div>
            <div className='p-details'>
                <div className='p-header'>
                    <h2>{name}</h2>
                </div>
                <div className='p-price'>
                    <p>Php {price}</p>
                </div>
                <div className='p-ing'>
                    <p>{ing}</p>
                </div>
                <div className='p-desc'>
                    <p>{description}</p>
                </div>
            </div>
            <div className='p-products-front'></div>
            <div className='p-products-back' style={{ backgroundColor: background }}></div>
        </div>
    );
}

export default ProductCard
