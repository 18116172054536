import React, { useCallback, useEffect, useState } from 'react'
import './HomePage.scss'
import ProductCard from './ProductCard/ProductCard';
import * as productService from '../../services/ProductService';
import companyLogo from '../../assets/images/7eats logo.png'

const HomePage = () => {
    const [selection, setSelection] = useState(1);
    const [oppositeCounter, setOppositeCounter] = useState();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [imageLoad, setImageLoad] = useState(true)

    const loadedAnimation = () => {
        const loadingPage = document.getElementById('loadingPage')
        if (loadingPage) {
            loadingPage.style.opacity = `0`
        }
    }

    useEffect(() => {
        productService.getAllProducts().then((response) => {
            const sortedProducts = response.data.sort((a, b) => parseInt(a.order) - parseInt(b.order));
            setProducts(sortedProducts);
            setOppositeCounter(sortedProducts.length);
            loadedAnimation();
            setTimeout(() => {
                setIsLoading(false);
            }, 250);
        });
    }, [])

    console.log(products)

    const calcDescLocation = useCallback((choice) => {
        const productCount = products.length;
        const position = [];

        if (productCount % 2 === 0) {
            for (let i = 0; i < productCount / 2; i++) {
                position.push(185 + i * 370);
                position.push(-185 - (i * 370));
            }
        } else {
            for (let i = 0; i < Math.floor(productCount / 2) + 1; i++) {
                if (i !== 0) {
                    position.push(i * 370);
                    position.push(-(i * 370));
                } else {
                    position.push(0);
                }
            }
        }

        position.sort((a, b) => a - b);

        return position[choice - 1];
    }, [products]); // Dependency array

    const calcImageAndHeaderLocation = useCallback((choice) => {
        const productCount = products.length;
        const position = [];

        if (productCount % 2 === 0) {
            for (let i = 0; i < productCount / 2; i++) {
                position.push(190 + i * 380);
                position.push(-190 - (i * 380));
            }
        } else {
            for (let i = 0; i < Math.floor(productCount / 2) + 1; i++) {
                if (i !== 0) {
                    position.push(i * 380);
                    position.push(-(i * 380));
                } else {
                    position.push(0);
                }
            }
        }

        position.sort((a, b) => a - b);

        return position[choice - 1];
    }, [products]);

    const handleAnimation = useCallback((number1, number2) => {
        const imgGal = document.getElementById('image-gallery');
        const headGal = document.getElementById('desc-header');
        const descGal = document.getElementById('desc-desc');
        const imgTrack = Array.from(imgGal.getElementsByClassName('pics'));

        for (let images of imgTrack) {
            images.style.transform = `translateX(${calcImageAndHeaderLocation(number1) * -1}px)`;
        }

        headGal.style.transform = `translateX(${calcImageAndHeaderLocation(number2) * -1}px)`;
        descGal.style.transform = `translateX(${calcDescLocation(number1) * -1}px)`;
    }, [calcImageAndHeaderLocation, calcDescLocation]);


    useEffect(() => {

        if (products) {
            handleAnimation(1, products.length);
            setOppositeCounter(products.length);
        }
    }, [products, handleAnimation])

    const productPage = document.getElementById('products');
    const pSideH = document.getElementById('p-side-h');
    const hero = document.getElementById('hero');
    const background2 = document.getElementById('background2');


    if (pSideH && productPage.clientWidth > 768) {
        pSideH.style.width = productPage.scrollHeight + 'px';
    }

    if (background2) {
        background2.style.height = hero.scrollHeight + 'px';
    }



    const handleRightButtonClick = () => {
        if (selection + 1 > products.length && oppositeCounter - 1 < 1) {
            setSelection(products.length)
            setOppositeCounter(1)
        }
        else {
            handleAnimation(selection + 1, oppositeCounter - 1);
            handleBackgroundChange(selection + 1, selection + 2)
            setSelection(selection + 1);
            setOppositeCounter(oppositeCounter - 1);
        }
    }

    const handleLeftButtonClick = () => {
        if (selection - 1 < 1 && oppositeCounter + 1 > products.length) {
            setSelection(1)
        }
        else {
            handleAnimation(selection - 1, oppositeCounter + 1);
            handleBackgroundChange(selection - 1, selection - 2)
            setSelection(selection - 1);
            setOppositeCounter(oppositeCounter + 1);
        }
    }

    console.log(selection)

    const handleBackgroundChange = (number1) => {
        let root = document.documentElement

        // root.style.setProperty('--backgroundGradientBefore', heroColorDictionary[number1 - 1]);
        root.style.setProperty('--backgroundGradientBefore', createBackgroundString(number1));
        root.style.setProperty('--beforeOpacity', `1`);

        setTimeout(() => {
            // root.style.setProperty('--backgroundGradient', heroColorDictionary[number1 - 1]);
            root.style.setProperty('--backgroundGradient', createBackgroundString(number1));
            root.style.setProperty('--beforeOpacity', `0`);
        }, 1000);
    }

    const createBackgroundString = (number1) => {
        return `linear-gradient(90deg, ${products[number1 - 1].alternateColor} 0%, ${products[number1 - 1].productColor} 100%)`;
    }

    const handleClickScroll = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    }

    const ProductName = React.memo(({ product }) => (
        <h2 key={product.id} className="j-h">{product.name}</h2>
    ));



    return (
        <div className='hp'>
            {isLoading && imageLoad ? (<div className='loadingPage' id='loadingPage'>
                <img src={companyLogo} alt='loading' />
            </div>) : null}
            <div>
                <div className='hero-background2' id='background2'></div>
                <div className='hero iceCream' id='hero'>
                    <div className='text'>
                        <div className='description'>
                            <div className='text-gallery desc-header' id='desc-header'>
                                {products ? (
                                    products.sort((productA, productB) => {
                                        // Convert order strings to numbers for accurate comparison
                                        const orderA = parseInt(productA.order, 10);
                                        const orderB = parseInt(productB.order, 10);

                                        // Sort ascending (lowest order number first)
                                        return orderA - orderB;
                                    }).reverse().map((product) => (
                                        <ProductName key={product.id} product={product} />
                                    ))
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className='text-gallery desc-desc' id='desc-desc'>
                                {products ? (
                                    products.sort((productA, productB) => {
                                        const orderA = parseInt(productA.order, 10);
                                        const orderB = parseInt(productB.order, 10);
                                        return orderA - orderB; // Sort ascending
                                    }).map((product) => (
                                        <p key={product.id} className="j-d">
                                            {product.longDescription}
                                        </p>
                                    ))
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <button className='product-button' onClick={() => handleClickScroll('products')}>Full Product List</button>
                    </div>
                    <div className='divider'></div>
                    <div className='image'>
                        <div className='image-gallery gallery' id='image-gallery'>
                            {products ? (
                                products.sort((productA, productB) => {
                                    const orderA = parseInt(productA.order, 10);
                                    const orderB = parseInt(productB.order, 10);
                                    return orderA - orderB; // Sort ascending
                                }).map((product) => (
                                    <img key={product.id} src={product.image} className="pics" alt="juice" onLoad={() => setImageLoad(false)} />
                                ))
                            ) : (
                                ""
                            )}
                        </div>
                        <div className='img-buttons'>
                            <div className='button' onClick={() => handleLeftButtonClick()}>
                                <svg className="svg-icon__arrow--left" viewBox="0 0 7 12" height="80%" width="100%" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ECDED1" fillRule="evenodd" d="M.107 6.033c0 .268.107.524.295.713l4.874 4.884c.394.394 1.032.394 1.424 0 .394-.394.394-1.033 0-1.427l-4.162-4.17L6.7 1.863c.394-.394.394-1.033 0-1.427-.392-.394-1.03-.394-1.424 0L.402 5.32c-.188.19-.295.445-.295.713z" />
                                </svg>
                            </div>
                            <div className='button' onClick={() => handleRightButtonClick()}>
                                <svg className="svg-icon__arrow--right" viewBox="0 0 7 12" height="80%" width="100%" preserveAspectRatio="MinYMin" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ECDED1" fillRule="evenodd" d="M6.893 5.967c0-.268-.107-.524-.295-.713L1.724.37C1.33-.024.692-.024.3.37c-.394.394-.394 1.033 0 1.427l4.162 4.17L.3 10.137c-.394.394-.394 1.033 0 1.427.392.394 1.03.394 1.424 0L6.598 6.68c.188-.19.295-.445.295-.713z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='products' id='products'>
                <div className='p-side-h' id='p-side-h'>
                    <h2>PRODUCTS</h2>
                </div>
                <div className='p-list'>
                    {products ? (
                        products.map((product) => (
                            <ProductCard
                                key={product.id}
                                name={product.name}
                                price={product.price}
                                ing={product.ingredients}
                                image={product.image}
                                description={product.shortDescription}
                                background={product.productColor}
                            />
                        ))
                    ) : (
                        ""
                    )}

                </div>
            </div>
            <div className='about-us' id='about-us'>
                <div>
                    <h1>Juice up your day, the wellness way</h1>
                </div>
            </div>
            <div className='contact' id='contact'>
                <div className='contact-left'>
                    <div className='contactUs'>
                        <h1>Contact Us</h1>
                    </div>
                    <div className='contact-address'>
                        <p>Address</p>
                        <h1>Acacia Estate, Taguig City</h1>
                    </div>
                    <div className='contact-phone'>
                        <p>Phone</p>
                        <h1>+63 917 884 1197</h1>
                    </div>
                    <div className='contact-email'>
                        <p>Email</p>
                        <h1>7Eats@gmail.com</h1>
                    </div>
                </div>

                <div className='message-box'>
                    <p style={{ color: 'white' }}>Message</p>
                    <input type='text' placeholder="[write here]" className='message-input' ></input>
                </div>
            </div>
        </div >
    )
}

export default HomePage