import axios from "axios";

const http = axios.create({
    baseURL:
        "https://seveneatsbackend-shy-wind-7382.fly.dev/api",
    // "http://localhost:8080/api",
});

http.interceptors.response.use(null,
    function (error) {
        return Promise.reject(error.response.data.message)
    }
);

export default http;